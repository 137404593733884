/** @jsx jsx */
import { Fragment, FC, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_WHITE,
	colors,
	media,
	THEME_PERIWINKLE,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_4UP,
} from '../../components/content/Showcase'
import { CopyButtonInline } from '../../components/CopyButton'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.workrise.next

type Props = {
	accentTheme: string
}

const Workrise: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			graphics: allFile(
				filter: { relativeDirectory: { eq: "workrise" } }
				sort: { fields: name, order: ASC }
			) {
				nodes {
					name
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		}
	`)

	const graphicsByName = useMemo(() => {
		return (data.graphics.nodes as { name: string }[]).reduce(
			(result, node) => {
				return {
					...result,
					[node.name]: node,
				}
			},
			{}
		)
	}, [data]) as Record<string, any>

	console.log(graphicsByName)

	return (
		<Fragment>
			<Helmet>
				<title>Workrise</title>
				<meta content="Workrise Case Study" property="og:title" />
				<meta content="Workrise Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="Forging a north star vision, roadmap and implementation system for energy sector workforce management."
				/>
				<meta
					name="description"
					content="Forging a north star vision, roadmap and implementation system for energy sector workforce management."
				/>
				<meta
					content="https://freeassociation.com/share/workrise.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/workrise.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Workrise</h1>
							<p className="--headline">
								Forging the future of workforce managment.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									At its core, Workrise powers the energy industry through the
									curation of specialist vendors and highly skilled individuals
									who tactically execute in the field—accelerating the pace of
									growth and innovation in the energy sector. With rapid growth
									came the continuous challenge of needing to prioritize
									day-to-day feature improvements at the expense of tackling
									long-term product reinvention based on key insights from its
									member base.
								</p>
								<p>
									Free Association brought a multi-disciplinary team to partner
									directly with cross-functional stakeholders and senior
									leadership to achieve reinvention for users. The team defined
									a clear picture of where Workrise needed to go and a plan for
									how to get there that was not only achievable, but inspiring
									and defensible to keep everyone pulling in the same direction
									for the long haul.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Northstar Vision & Prototyping</li>
									<li>User Experience Design</li>
									<li>UI & Visual Design</li>
									<li>Brand Systems & Guidelines</li>
									<li>UI Libraries</li>
									<li>Product Leadership</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={graphicsByName.workrise_00.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Leveraging customer and stakeholder inputs alongside a series
									of audits, the team quickly identified key opportunity areas
									for rapid design exploration—each concept exploring different
									information architectures & design principles that enable the
									product to scale with flexibility and cohesion.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Product Brand Harmony">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Bringing Product and Brand together to create a relationship
										where product experience fulfills brand promise, and the
										symbiotic relationship fuels scale.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Growth & Product Strategy">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We designed user acquisition-to-activation funnels to
										minimize drop-off and evolved features to help shape
										competitive product moats and increase retention.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Comprehensive UX">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We designed for the end-to-end user experience from
										onboarding through the core app flow, including variations
										that demonstrated key differences in new user versus active
										user scenarios.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Roadshow Prototype">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										To achieve organizational alignment and unlock funding, the
										vision was brought to life with an interactive prototype and
										story to showcase the work to leadership and across the
										company.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1920} height={1080} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/media/workrise/FA_CS_Workrise_Strategy-Loop.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1920} height={1920} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/media/workrise/FA_CS_Workrise_Prototype-Demo.mov" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“Knowing what could be, what we’d like to be, and what we
									should work towards was a driving force for us.”
								</p>
								<footer>
									<p className="--small">
										<strong>Dorelle Rabinowitz</strong>
										<br />
										VP Design
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Applying the vision from concept to reality became the next
									phase—working with stakeholders to update ongoing work into
									alignment with north star design foundations. We created an
									iterated MVP and a tactical product roadmap that enabled
									immediate traction towards realizing the vision.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Flexible & Scalable">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										From the beginning, core functionality and design decisions
										were put through robust user and content scenarios to ensure
										adaptability over time and across user needs. The MVP then
										became a stress-test for validating the scale of all north
										star vision principles.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Iterative & Agile">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Breaking down the vision into prioritized milestones
										empowered the team to simultaneously keep up with day-to-day
										features while making incremental steps forward toward the
										long-term product vision.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						chrome={false}
						layout={SHOWCASE_LAYOUT_4UP}
						images={[
							graphicsByName.workrise_01_01.childImageSharp.fluid,
							graphicsByName.workrise_01_02.childImageSharp.fluid,
							graphicsByName.workrise_01_03.childImageSharp.fluid,
							graphicsByName.workrise_01_04.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={THEME_WHITE}>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<Container maxWidth="75rem">
						<FullBleedImage
							fluidImageData={graphicsByName.workrise_02.childImageSharp.fluid}
						/>
					</Container>
				</div>
			</Slide>
			<Slide prefferedTheme={THEME_WHITE}>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<Container maxWidth="75rem">
						<FullBleedImage
							fluidImageData={graphicsByName.workrise_03.childImageSharp.fluid}
						/>
					</Container>
				</div>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--large">Have a North Star Design challenge?</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(Workrise, THEME_PERIWINKLE)
